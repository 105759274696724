<script setup>

</script>

<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.9805 17.4374C21.036 17.4374 21.0901 17.4211 21.1363 17.3905C21.1825 17.3598 21.2186 17.3162 21.24 17.2652C21.2615 17.2141 21.2674 17.1578 21.2571 17.1034C21.2467 17.0489 21.2204 16.9988 21.1816 16.9592L15.6691 11.3539C15.63 11.3141 15.5798 11.2869 15.5251 11.2757C15.4704 11.2645 15.4137 11.2699 15.362 11.2911C15.3104 11.3123 15.2662 11.3483 15.2351 11.3947C15.204 11.4411 15.1875 11.4957 15.1875 11.5515V16.5936C15.1875 16.8174 15.2764 17.032 15.4346 17.1902C15.5929 17.3485 15.8075 17.4374 16.0313 17.4374H20.9805Z"
            fill="#131933"/>
        <path
            d="M13.8516 18.7734C13.5628 18.4885 13.3333 18.1492 13.1764 17.7751C13.0195 17.4009 12.9383 16.9994 12.9375 16.5938V10.125H7.875C6.68261 10.1285 5.54006 10.6038 4.69691 11.4469C3.85376 12.2901 3.37852 13.4326 3.375 14.625V30.375C3.375 31.5685 3.84911 32.7131 4.69302 33.557C5.53693 34.4009 6.68153 34.875 7.875 34.875H18C19.1935 34.875 20.3381 34.4009 21.182 33.557C22.0259 32.7131 22.5 31.5685 22.5 30.375V19.6875H16.0312C15.6255 19.6869 15.224 19.6058 14.8498 19.4489C14.4756 19.292 14.1363 19.0624 13.8516 18.7734Z"
            fill="#131933"/>
        <path
            d="M26.1563 8.43736H31.1055C31.161 8.43741 31.2151 8.42109 31.2613 8.39045C31.3075 8.35982 31.3436 8.31622 31.365 8.26515C31.3865 8.21408 31.3924 8.1578 31.3821 8.10337C31.3717 8.04895 31.3454 7.99881 31.3066 7.95924L25.7941 2.35392C25.755 2.31415 25.7048 2.28693 25.6501 2.27573C25.5954 2.26454 25.5387 2.26987 25.487 2.29106C25.4354 2.31225 25.3912 2.34833 25.3601 2.39471C25.329 2.44109 25.3125 2.49567 25.3125 2.5515V7.59361C25.3125 7.81739 25.4014 8.032 25.5596 8.19023C25.7179 8.34847 25.9325 8.43736 26.1563 8.43736Z"
            fill="#131933"/>
        <path
            d="M26.1562 10.6875C25.3377 10.6812 24.5544 10.3533 23.9755 9.77445C23.3967 9.19561 23.0688 8.41233 23.0625 7.59375V1.125H15.4687C14.3503 1.1263 13.278 1.5712 12.4871 2.36208C11.6962 3.15297 11.2513 4.22527 11.25 5.34375V7.875H14.2116C14.5896 7.87651 14.9636 7.9527 15.312 8.09919C15.6605 8.24567 15.9766 8.45957 16.2422 8.72859L23.9175 16.5333C24.453 17.0766 24.7519 17.8095 24.7493 18.5723V28.125H28.4794C30.7652 28.125 32.6243 26.2322 32.6243 23.9062V10.6875H26.1562Z"
            fill="#131933"/>
    </svg>

</template>

<style scoped>

</style>
