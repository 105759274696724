<script setup>

</script>

<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0812 5.39409C16.6852 3.5353 19.3148 3.53531 19.9188 5.39409L22.0767 12.0354C22.3468 12.8667 23.1214 13.4295 23.9955 13.4295H30.9786C32.933 13.4295 33.7456 15.9305 32.1645 17.0793L26.515 21.1838C25.8079 21.6976 25.512 22.6082 25.7821 23.4395L27.94 30.0808C28.544 31.9396 26.4165 33.4853 24.8353 32.3365L19.1859 28.2319C18.4788 27.7182 17.5212 27.7182 16.8141 28.2319L11.1647 32.3365C9.58349 33.4853 7.45604 31.9396 8.05999 30.0808L10.2179 23.4395C10.488 22.6082 10.1921 21.6976 9.48498 21.1838L3.83553 17.0793C2.25435 15.9305 3.06697 13.4295 5.02141 13.4295H12.0045C12.8786 13.4295 13.6532 12.8667 13.9233 12.0354L16.0812 5.39409Z" fill="#131933"/>
    </svg>

</template>

<style scoped>

</style>
