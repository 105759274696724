<script setup>

</script>

<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.9676 11.9109C31.759 11.7009 31.5109 11.5343 31.2375 11.4209C30.9641 11.3074 30.671 11.2493 30.375 11.25H25.875V10.125C25.875 8.03642 25.0453 6.03338 23.5685 4.55653C22.0916 3.07969 20.0886 2.25 18 2.25C15.9114 2.25 13.9084 3.07969 12.4315 4.55653C10.9547 6.03338 10.125 8.03642 10.125 10.125V11.25H5.625C5.02826 11.25 4.45597 11.4871 4.03401 11.909C3.61205 12.331 3.375 12.9033 3.375 13.5V28.6875C3.375 31.4297 5.69531 33.75 8.4375 33.75H27.5625C28.8889 33.7504 30.1629 33.2318 31.1119 32.3051C31.5895 31.8495 31.9698 31.3018 32.2298 30.6952C32.4899 30.0885 32.6243 29.4354 32.625 28.7754V13.5C32.6259 13.2048 32.5683 12.9124 32.4554 12.6396C32.3426 12.3669 32.1768 12.1192 31.9676 11.9109ZM23.3782 19.2656L17.0782 27.1406C16.9747 27.2699 16.844 27.3747 16.6954 27.4477C16.5467 27.5206 16.3838 27.5598 16.2183 27.5625H16.2C16.0375 27.5625 15.8769 27.5273 15.7293 27.4593C15.5817 27.3914 15.4506 27.2922 15.345 27.1687L12.645 24.0131C12.549 23.9008 12.476 23.7708 12.4302 23.6303C12.3845 23.4898 12.3669 23.3417 12.3784 23.1944C12.3898 23.0471 12.4302 22.9035 12.4972 22.7718C12.5642 22.6401 12.6565 22.5229 12.7688 22.4269C12.881 22.3308 13.0111 22.2579 13.1516 22.2121C13.2921 22.1664 13.4402 22.1487 13.5875 22.1602C13.7348 22.1717 13.8784 22.2121 14.0101 22.2791C14.1418 22.3461 14.259 22.4383 14.355 22.5506L16.1719 24.6734L21.6218 17.8594C21.8083 17.6263 22.0797 17.4768 22.3764 17.4438C22.6731 17.4109 22.9707 17.4971 23.2038 17.6836C23.4369 17.8701 23.5864 18.1415 23.6194 18.4382C23.6523 18.7349 23.5661 19.0325 23.3796 19.2656H23.3782ZM23.625 11.25H12.375V10.125C12.375 8.63316 12.9676 7.20242 14.0225 6.14752C15.0774 5.09263 16.5082 4.5 18 4.5C19.4918 4.5 20.9226 5.09263 21.9775 6.14752C23.0324 7.20242 23.625 8.63316 23.625 10.125V11.25Z" fill="#131933"/>
    </svg>

</template>

<style scoped>

</style>
