<script setup>

import Card from "@/Components/Card.vue";
import CardGame from "@/Components/CardGame.vue";
import {Link} from '@inertiajs/vue3'

const props = defineProps({
    title: String,
    games: Array,
    url: String,
    isMany: {
        type: Boolean,
        default: false,
    }
})


</script>

<template>
    <h5 class="text-base lg:text-xl font-medium leading-120 py-3 px-1 font-['Unbounded']">
        {{ title }}
    </h5>
    <card
        :class="isMany ? 'lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 lg:gap-2' : 'lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 lg:gap-2.5'"
        class="grid grid-cols-1  lg:px-2.5 pb-2.5 lg:py-2.5 font-medium">

        <template v-for="product in props.games">
            <CardGame
                :class="isMany ? 'lg:[&:nth-child(-n+9)]:flex xl:[&:nth-child(-n+12)]:flex' :
                    'lg:[&:nth-child(-n+6)]:flex 2xl:[&:nth-child(-n+9)]:flex'"
                class="[&:nth-child(-n+3)]:flex hidden" :product="product" :mini-img="isMany"/>
        </template>
        <div class="col-span-full px-9 pt-1.5 font-normal">
            <Link :href="url"
                  method="get"
                  class="border grow flex items-center justify-center border-slate-500 rounded-lg w-full h-10 transition-colors hover:bg-slate-200 text-sm font-normal">
                <span>{{ $t('Show all') }}</span>
            </Link>

        </div>
    </card>

</template>

<style scoped>

</style>
